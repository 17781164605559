<template>
  <b-modal
    :id="modalRef"
    :ref="modalRef"
    v-bind="$attrs"
    size="md"
    footer-class="justify-content-center"
    hide-header-close
    v-on="$listeners"
  >
    <slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
  </b-modal>
</template>

<script>
export default {
  name: 'CtInfoModal',
  props: {
    modalRef: null,
  },
}
</script>

<style lang="scss" scoped>
button {
  min-width: 150px;
}
</style>
